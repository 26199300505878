.large-container {
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 1240px) {
        padding: 0 20px;
    }
}

.medium-container {
    max-width: 1024px;
    margin: 0 auto;
}

.small-container {
    max-width: 800px;
    margin: 0 auto;
}

.blocks-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;
    @media screen and (max-width: 1024px) {
        gap: 40px;
    }
}

.callout {
    background-color: rgba(0, 0, 0, .05);
    padding: 60px;

    @media screen and (max-width: 1024px) {
        padding: 20px;
    }
}