header {
    padding: 30px 0;
    position: sticky;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 50;

    .large-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        nav {
            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 40px;
                list-style: none;

                @media screen and (max-width: 1024px) {
                    gap: 16px;
                }

                li a {
                    text-decoration: none;
                    font-family: "Playfair Display", serif;
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                    transition: all ease .15s;

                    &:hover {
                        opacity: .2;
                    }
                }
            }
        }
    }
}