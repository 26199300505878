/**
 * Backend
 */

.backend {
    * {
        font-family: "Poppins", sans-serif;
    }

    &-wrapper {
        display: flex;
    }

    &-menu {
        width: 100%;
        max-width: max-content;
        min-height: calc(100vh - 50px);
        background: $grey;
        color: $white;

        nav > ul {
            list-style: none;
            display: flex;
            flex-direction: column;

            > li {
                padding: .5rem 1rem;

                span {
                    font-size: 12px;
                    font-weight: 500;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $black;

                    ul {
                        max-height: 200px;
                    }
                }

                ul {
                    max-height: 0;
                    transition: all ease .3s;
                    overflow: hidden;
                    padding-left: .7rem;
                    list-style: none;

                    li {
                        &:first-child {
                            padding-top: .5rem;
                        }

                        a {
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                }

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        opacity: 60%;
                    }
                }
            }
        }

    }

    &-container {
        width: 100%;
        max-width: 100%;
        flex-grow: 1;
        background: $lightgrey;
    }

    &-small-container {
        max-width: 800px;
        margin: 0 auto;
        padding: 2rem 0;

        h1 {
            margin-bottom: 2rem;
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        fieldset {
            border: none;
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }

        label {
            font-size: 1rem;
            font-weight: 500;
        }

        input, textarea {
            border: none;
            padding: .5rem;
            outline-color: $grey;
            &:not([type="submit"]) {
                background-color: $lightgrey;
            }

            &[type='submit'] {
                width: max-content;
                margin: 0 auto;
            }
        }
    }

    &-2cols-form {
        display: grid;
        gap: 2rem;
        grid-template-columns: (2fr 1fr);

        &-wrapper {
            padding: 1rem;
            background-color: $white;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        }

        > div {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;

        &-item {
            display: flex;
            background-color: $white;
            padding: 1rem;

            &-title-wrapper {
                display: flex;
                flex-direction: column;
            }

            &-title {
                font-weight: 500;

                &-image {
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &-actions {
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 12px;
                max-height: 0;
                overflow: hidden;
                transition: all ease .3s;
                opacity: 0;
            }

            &:hover &-actions {
                max-height: 30px;
                opacity: 100%;
            }

            &-delete {
                color: $red;
            }

            &-modify {
                color: $blue;
            }
        }

        &-images {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 1rem;
            list-style: none;

            &-wrapper {
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &::after {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }
            }

            &-wrapper:hover &-actions {
                opacity: 1;
                visibility: visible;
            }

            &-actions {
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 10;
                opacity: 0;
                visibility: hidden;
            }

            &-delete {
                width: 1.5rem;
                height: 1.5rem;
                display: block;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $red;
                }
            }
        }
    }
}