.films-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }

    &-item {
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            color: $white;
            background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: all ease .5s;

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            h1 {
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 0;
            }

            span {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                margin-bottom: 10px;
            }

            p {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                overflow: hidden;
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                transition: .3s all ease;
            }

            &:hover {
                background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 100%);
                p {
                    padding-bottom: 100%;
                }
            }
        }
    }
}

.wardrobe-decoration {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: clamp(250px, 40vw, 600px);
        height: clamp(250px, 40vw, 600px);
        background-image: url("../images/wardrobe.svg");
        opacity: 10%;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}