.add-comment-form {
    textarea {
        width: 100%;
        padding: 1rem;

        &:focus {
            outline-color: $black;
        }
    }

    .button-black {
        margin: 15px auto 0 auto;
    }

    .comments-area {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem 0;

        .comment-item {
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            background-color: rgba(255, 255, 255, .2);

            p {
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
            }

            span {
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}