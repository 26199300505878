$black: #212529;
$grey: #343a40;
$lightgrey: #f8f9fa;

$blue: #4361ee;
$lightblue: #4895ef;

$white: #fff;

$green: #2ec4b6;
$red: #FF7A63;

$beige: #F6F2E6;