.top-bar {
    width: 100%;
    padding: 0 2rem;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;

    &-user {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: .5rem 0;

        span {
            font-size: 12px;
        }
    }

    &-name {
        font-size: 1rem;
        color: $white;
        text-decoration: none;
    }

    &-links {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: stretch
    }

    &-backend {
        font-size: 1rem;
        color: $white;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: .5rem;
        background-color: $grey;
        padding: .5rem 1rem;
        height: 100%;

        div {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
                fill: $white;
            }
        }

        span {
            font-size: 12px;
        }
    }
}