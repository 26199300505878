/**
 * Globals
 */
@import "global/vars";
@import "global/fonts";
@import "global/buttons";
@import "global/containers";
@import "global/font-style";
@import "global/images";
@import "global/form";

/**
 * Backend
 */
@import "backend/global";

/**
 * Components
 */
@import "components/top-bar";
@import "components/image-picker";
@import "components/actor-repeater";
@import "components/comment-form";

/**
 * Partials
 */
@import "partials/header";
@import "partials/footer";


/**
 * Pages
 */
@import "pages/homepage";
@import "pages/film-single";
@import "pages/film-archive";


/**
 * Reset CSS
 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: $beige;
    overflow-x: clip;
}