.button-blue {
    display: block;
    padding: .5rem 1rem;
    background-color: $blue;
    transition: all ease .3s;
    color: $white;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    width: max-content;

    &:hover {
        background-color: $lightblue;
        cursor: pointer;
    }
}


.button-black {
    display: block;
    padding: 15px 30px;
    background-color: $black;
    transition: all ease .3s;
    color: $white;
    text-decoration: none;
    font-family: "Playfair Display", serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    width: max-content;
    border: 1px solid $black;

    &:hover {
        background-color: transparent;
        color: $black;
        cursor: pointer;
    }
}