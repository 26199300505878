.image-picker {
    &-popin {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all ease .3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &[aria-expanded="true"] {
            opacity: 100%;
            visibility: visible;
        }


        &-container {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
            background: $white;
            padding: 3rem;
            position: relative;
            max-height: 80vh;
            overflow-y: auto;
        }
    }

    &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: none;
        cursor: pointer;
        background: none;

        svg {
            width: 100%;
            height: 100%;
            fill: $black;
        }
    }

    &-item {
        &:hover {
            cursor: pointer;
        }

        &::before {
            content: "";
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: $blue;
            position: absolute;
            top: 5px;
            right: 5px;
            opacity: 0;
            visibility: hidden;
            transition: all ease .3s;
            background-image: url("../images/icons/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;
            z-index: 10;
        }

        &[aria-selected='true']::before {
            opacity: 100%;
            visibility: visible;
        }
    }

    &-validate {
        margin: 1rem auto 0 auto;
    }

    &.image-picker-multiple &-preview-wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 5px;
    }

    &.image-picker-multiple &-preview-wrapper &-preview {
        width: 100%;

        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-preview {
        position: relative;
        width: max-content;

        img {
            max-width: 150px;
            max-height: 150px;
        }
    }

    &-preview:hover &-reset {
        opacity: 100%;
        visibility: visible;
    }

    &-reset {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        border: none;
        cursor: pointer;
        background: none;
        opacity: 0;
        visibility: hidden;
        transition: all ease .3s;
        z-index: 10;

        svg {
            width: 100%;
            height: 100%;
            fill: $black;
        }
    }


    &-add-form {
        width: 100%;
        height: 100%;
        padding: 2rem;
        border: dashed 1px $blue !important;
        margin: 2rem 0;
        position: relative;

        label {
            display: none;
            width: 100%;
            height: 100%;
            cursor: pointer;
            text-align: center;
            color: $blue;
            opacity: 70%;
            font-weight: 500;
            margin: 0 auto;
        }

        input[type="file"] {
            display: none;
        }


        .image-picker-add-preview {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            input[type="submit"] {
                display: block;
            }

            .backend-list-images-wrapper {
                width: 100px;
            }
        }


        &:has(img[src='']) {
            .backend-list-images-wrapper, .image-picker-add-preview, input[type='submit'] {
                display: none;
            }

            label {
                display: block;
            }
        }
    }
}