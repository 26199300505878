body:has(.user-form){
    background-color: $white;
    header, footer {
        display: none;
    }
}

.user-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 200px auto 0 auto;
    max-width: 500px;
    padding: 2rem;
    background-color: $lightgrey;
    border-radius: .5rem;

    input {
        padding: .5rem;
        border: 1px solid $grey;
        border-radius: .5rem;

        &:focus {
            outline: none;
            border: 2px solid $blue;
        }

        &[type='submit'] {
            background-color: $blue;
            color: $white;
            border: none;
            cursor: pointer;
            transition: all .2s ease-in-out;

            &:hover {
                background-color: $lightblue;
            }
        }
    }
}