h1 {
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    font-weight: 900;
    line-height: 50px;
    margin-bottom: 15px;
    @media screen and (max-width: 1024px) {
        font-size: 36px;
        line-height: 40px;
    }

    &.big-title {
        font-size: 96px;
        line-height: 100px;
        @media screen and (max-width: 1024px) {
            font-size: 48px;
            line-height: 50px;
        }
    }
}

h2 {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 5px;
    @media screen and (max-width: 1024px) {
        font-size: 28px;
        line-height: 30px;
    }
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 5px;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 20px;
    }
}

p, span {
    font-family: 'Playfair Display', serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 25px;
    }
}

.small-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.title-centered {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}