.film-heading {
    display: flex;
    gap: 80px;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
    }

    > div {
        width: 66%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    img {
        width: 33%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
}

.banner img {
    width: 100%;
}

.actors-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 35px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 15px;
    }

    &-item {
        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            @media screen and (max-width: 1024px) {
                height: 200px;
            }
        }

        h1 {
            font-size: 24px;
            font-family: "Playfair Display", serif;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 0;
            @media screen and (max-width: 1024px) {
                font-size: 16px;
            }
        }

        span {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
        }
    }
}

.film-trailer {
    width: 100%;
    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
    }
}